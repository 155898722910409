<template>
  <div class="tile is-parent">
    <article class="tile is-child box detail-page-tile">
      <div class="columns is-gapless">
        <div class="column">
          <p v-if="(innerValue.firstName != null && innerValue.firstName != '') || (innerValue.lastName != '' && value.lastName != null)"
            class="title">Invoices for {{ innerValue.title }} {{ innerValue.firstName }} {{ innerValue.lastName }}</p>
          <p v-else-if="innerValue.companyName != null || innerValue.companyName != ''"
            class="title">Invoices for {{ innerValue.companyName }}</p>
          <p v-else
            class="title">Invoices</p>
          <p v-if="innerValue.totalAmount === ''"
            class="subtitle"> Total Amount: $0.00</p>
          <p v-else
            class="subtitle"> Total Amount: ${{ innerValue.totalAmount | formatNumber($userInfo.locale) }}</p>
          <div class="field has-addons">
            <div class="control has-icons-left has-icons-right">
              <input class="input"
                v-model="query"
                type="text"
                placeholder="Start typing to filter">
              <span class="icon is-left">
                <i class="mdi mdi-magnify mdi-18px" />
              </span>
            </div>
            <div class="control">
              <a class="button is-primary is-outlined tooltip"
                data-tooltip="Reset search"
                @click="reset()">
                <span class="icon">
                  <i class="mdi mdi-close mdi-24px" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        v-if="activeItems.length > 0"
        :columns="columns"
        :total-rows="activeItems.length"
        :is-loading="false"
        :is-title-checkable="true"
        title-checkbox-location="front"
        :show-pagination="false"
        :is-title-checked.sync="isTitleCheck"
        @title-checkbox-changed="titleCheckboxChanged">
        <tr v-for="(item, index) in activeItems"
          :key="item.invoiceId"
          v-show="!item.isDeleted">
          <td class="has-vertical-middle has-text-centered is-content-width">
            <input class="is-checkradio is-box is-primary"
              :class="{ 'has-background-color': item.isChecked }"
              :id="`report${index}`"
              :name="`report${index}`"
              :disabled="readOnlyView"
              type="checkbox"
              @change="addReceivedAmount(item.isChecked, item.balance , index)"
              v-model="item.isChecked">
            <label :for="`report${index}`"
              data-label-empty />
          </td>
          <td class="has-vertical-middle">{{ item.invoiceRef }}</td>
          <td class="has-vertical-middle">{{ $filters.formatDateLocale(item.invoiceDate, $userInfo.locale) }}</td>
          <td class="has-vertical-middle">{{ item.invoiceIncGst | formatNumber($userInfo.locale) }}</td>
          <td class="has-vertical-middle">{{ item.balance | formatNumber($userInfo.locale) }}</td>
          <td width="12%">
            <vue-numeric v-if="item.isChecked"
              :minus="true"
              placeholder="Amount Required"
              class="input has-text-right"
              v-model.number="item.receivedAmount"
              :precision="2"
              :disabled="readOnlyView"
              v-tippy="{ theme: 'danger', enabled: item.isChecked && !$v.entity.invoiceItems.$each[index].receivedAmount.validAmount }"
              content="Received Amount cannot be zero"
              :class="{ 'is-danger' : $v.entity.invoiceItems.$each[index].receivedAmount.$error }"
              @input="calculateTotal()" />
            <vue-numeric v-else
              :minus="true"
              :min="0.00"
              class="input has-text-right"
              v-model.number="item.receivedAmount"
              :precision="2"
              disabled="true" />
          </td>
          <td width="20%">
            <v-date-picker v-model="item.receivedDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :min-date="$company.setting.allowInvoicePreviousMonth ? null : $filters.firstDayOfMonth()"
              @input="item.receivedDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="control has-icons-left">
                  <input v-if="item.isChecked && !readOnlyView"
                    type="text"
                    class="input"
                    placeholder="Received Date"
                    :value="inputValue"
                    :class="{ 'is-danger' : $v.entity.invoiceItems.$each[index].receivedDate.$error }"
                    v-on="inputEvents"
                    v-tippy="{ theme: 'danger', enabled: item.isChecked && !$v.entity.invoiceItems.$each[index].receivedDate.required }"
                    content="Received Date is required"
                    readonly>
                  <input v-else
                    type="text"
                    class="input"
                    placeholder="Received Date"
                    :value="$filters.formatDateLocale(item.receivedDate, $userInfo.locale)"
                    :disabled="readOnlyView || !item.isChecked"
                    readonly>
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-calendar mdi- 18px" />
                  </span>
                </div>
              </template>
            </v-date-picker>
          </td>
          <td class="has-vertical-middle has-text-right">
            <div class="control">
              <input v-if="item.isChecked"
                class="input"
                placeholder="Reference"
                :disabled="readOnlyView"
                :maxlength="12"
                v-model="item.chequeNo">
              <input v-else
                disabled
                class="input"
                placeholder="Reference"
                v-model="item.chequeNo">
            </div>
          </td>
        </tr>
      </bulma-table>
    </article>
  </div>
</template>

<script>
import { FocusInserted } from '@/components/directives'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { EventHubTypes } from '@/enums'
import _cloneDeep from 'lodash/cloneDeep'
import { ReceiptPaymentColumns } from './columns'
import VueNumeric from '@/components/VueNumeric'
import BulmaTable from '@/components/BulmaTable'

export default {
  name: 'ReceiptPaymentDetail',
  inject: ['$vv'],
  components: {
    BulmaTable,
    VueNumeric
  },
  directives: {
    FocusInserted
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null,
    disableChange: {
      type: Boolean,
      default: true
    },
    readOnlyView: {
      type: Boolean
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      isDisable: false,
      buttonText: 'Add',
      totalAmount: 0.0,
      bankAccount: null,
      chequeNumber: '',
      isTitleCheck: false,
      moneyMask: {
        precision: 2
      },
      query: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    columns() {
      return ReceiptPaymentColumns
    },
    activeItems() {
      // return this.innerValue.invoiceItems
      const query = this.query.toLowerCase()
      const results = this.innerValue.invoiceItems
        ? this.innerValue.invoiceItems.filter((q) => q.invoiceRef.toLowerCase().includes(query))
        : this.innerValue.invoiceItems
      return results
    },
    addButton() {
      return {
        'mdi-plus': true
      }
    },
    addButtonClass() {
      return {
        // 'is-primary': true
        'is-success': true
        // 'is-loading': this.assetButtonLoading
      }
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },

  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
      this.isTitleCheck = false
    })
    this.$v.entity.$touch()
  },
  async created() {
    if (!this.disableChange) {
      this.isTitleCheck = true
      this.chequeNumber = this.innerValue.invoiceItems[0].chequeNo
      this.innerValue.invoiceItems.forEach(function (items) {
        items.receivedAmount = roundAwayFromZero(items.receivedAmount, 2)
      })
    }
  },
  methods: {
    titleCheckboxChanged(value) {
      if (this.disableChange) {
        if (value) {
          let filter = this.activeItems.filter((id) => id.isChecked === false)
          if (filter.length === this.activeItems.length) {
            this.activeItems.forEach(item => {
              item.isChecked = true
              item.receivedAmount = item.receivedAmount !== 0 ? item.receivedAmount : roundAwayFromZero(item.balance, 2)
            })
          }
          else {
            filter.forEach(item => {
              item.isChecked = true
              item.receivedAmount = item.receivedAmount !== 0 ? item.receivedAmount : roundAwayFromZero(item.balance, 2)
            })
          }
        }
        else {
          let filter = this.activeItems.filter((i) => i.isChecked === true)
          if (filter.length === this.activeItems.length) {
            this.activeItems.forEach(item => {
              item.isChecked = false
              item.receivedAmount = 0
            })
          }
        }
        this.calculateTotal()
      }
    },

    reset() {
      this.query = ''
      this.calculateTotal()
    },

    //Calculating Total Amount
    calculateTotal() {
      this.innerValue.totalAmount = 0.0
      for (let i = 0; i < this.activeItems.length; i++) {
        this.innerValue.totalAmount = roundAwayFromZero(
          roundAwayFromZero(this.innerValue.totalAmount, 2) + roundAwayFromZero(this.activeItems[i].receivedAmount, 2),
          2
        )
      }
    },
    addReceivedAmount(check, balance, index) {
      if (this.disableChange) {
        if (check) {
          this.activeItems[index].receivedAmount = roundAwayFromZero(balance, 2)
          this.activeItems[index].chequeNo = this.chequeNumber
        } else {
          this.activeItems[index].receivedAmount = 0.0
          this.activeItems[index].chequeNo = ''
        }
        this.checkTitleCheck(check)
        this.calculateTotal()
      }
    },
    checkTitleCheck(check) {
      let filter = this.activeItems.filter((id) => id.isChecked === !check)
      if (filter.length === 0 && check) {
        this.isTitleCheck = true
      } else {
        this.isTitleCheck = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
