<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="readOnlyView"
      :show-delete="false"
      :show-save="isSaveButton && !readOnlyView"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <receipt-payment-side-menu v-if="entity"
          ref="sideMenu"
          v-model="entity" />
      </div>
      <div class="column">
        <router-view v-if="entity"
          v-model="entity"
          ref="currentChild"
          :disable-change="entity.isNew"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name"
          :read-only-view="readOnlyView" />
      </div>
    </div>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="saveEntity('ok')"
      @cancel="saveEntity('print')"
      @close="saveEntity('close')"
      :ok-text="'Save & Print'"
      :cancel-text="'Print'"
      :close-text="'Close'"
      :show-close="true">
      <p slot="text-title">Do you want to Save and Print?</p>
      <p slot="text-content">
        This will save your selected Receipt Payment and Print.
      </p>
    </confirm-modal>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <!-- <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal> -->
  </div>
</template>

<script>
import ReceiptPaymentSideMenu from './ReceiptPaymentSideMenu'
import ReceiptPaymentService from './ReceiptPaymentService'
import ReceiptPaymentRoutes from './route-types'
import AppDetailHeader from '@/components/AppDetailHeader'
import QuickInvoiceService from '@/views/quickinvoice/QuickInvoiceService'
import { UnsavedModal, ConfirmModal } from '@/components/BulmaModal'
import ReceiptEntryRoutes from '../receiptentry/route-types'
import HttpStatus from '@/components/http-status'
import EventBus from '@/components/EventBus'
import { AppHeaderButtonTypes, EventHubTypes, AssetTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import StoreUtil from '@/store/utils'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import ReceiptPaymentValidation from './ReceiptPaymentValidation'

const title = 'Receipt Entry'

export default {
  name: 'ReceiptPaymentView',
  components: {
    AppDetailHeader,
    [ReceiptPaymentSideMenu.name]: ReceiptPaymentSideMenu,
    UnsavedModal,
    ConfirmModal
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, ReceiptPaymentValidation],
  props: {
    returnUrl: String,
    type: String
  },
  data() {
    return {
      entity: null,
      isUnsavedModalActive: false,
      // isSaveConflictModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      toRoute: null, // stores "to" route of beforeRouteLeave
      isSaveButton: false,
      counter: 0,
      isConfirmModalActive: false
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.receiptEntryGroup.$error
    }
  },
  watch: {
    'entity.invoiceItems': {
      handler: _debounce(function (newVal) {
        if (newVal) {
          this.$forceUpdate()
          // this.saveSnapshot(_cloneDeep(this.entity))
          this.isSaveContinue = false
          this.isSaveButton = false
          let found = this.entity.invoiceItems.find((id) => id.isChecked === true)
          if (found) {
            this.isSaveButton = true
          }
        }
      }, 500),
      deep: true
    }
  },
  beforeDestroy() {
    if (_isEmpty(this.returnRoute)) {
      this.setReadOnlyView(false)
    }
    // this.clearSessionStorage()
    // this.clearSnapshots(this.entity.itemQuickId)
  },
  async created() {
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.AssetId)
    }
    await this.getEntity()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          this.entity.deleted = true
          this.save(true)
          break
        case AppHeaderButtonTypes.Save:
          this.confirmSave()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.AssetId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.AssetId)
        }
        this.entity = this.currentSnapshot
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
      // await this.getStoreItem(this.$route.params.AssetId)
      // this.entity = _cloneDeep(this.currentSnapshot)
    },
    confirmSave() {
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }
      this.isConfirmModalActive = true
    },
    async saveEntity(confirmSave) {
      this.isConfirmModalActive = false
      if (confirmSave === 'ok') {
        this.save()
      } else if (confirmSave === 'print') {
        this.print1()
      }
    },
    async save(isDelete) {
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          response = await ReceiptPaymentService.putReceiptPayment(_cloneDeep(this.entity), this.snapshotDiff)
        } else if (this.snapshotDiff) {
          response = await ReceiptPaymentService.putReceiptPayment(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes detected. Not saved')
          return false
        }
        this.$hideSpinner()

        if (this.isSaveContinue) {
          let found = this.entity.invoiceItems.find((id) => id.isChecked === true)
          if (found) {
            await this.print1()
            this.$notification.success('Receipt Entry', isDelete ? 'Receipt Entry set to In-Active' : 'Save successful')
          }
          await this.getEntity(true)
        } else if (response && response.status === HttpStatus.OK) {
          this.print1()
          await this.getEntity(true)
          this.$notification.success('Receipt Entry', isDelete ? 'Receipt Entry set to In-Active' : 'Save successful')
          this.entity.totalAmount = 0
          this.isSaveButton = false
        } else if (response && response.status === HttpStatus.NO_CONTENT) {
          this.print1()
          await this.getEntity(true)
          this.$notification.success('Receipt Entry', isDelete ? 'Receipt Entry set to In-Active' : 'Save successful')
          this.$router.push({
            name: ReceiptEntryRoutes.ReceiptEntryListView.name
          })
        } else if (response && response.status === HttpStatus.CREATED) {
          this.isUnsavedModalActive = false
          await this.getEntity(true)
          this.$notification.success('Receipt Entry', isDelete ? 'Receipt Entry set to In-Active' : 'Save successful')
          this.entity.totalAmount = 0
        }
        // reloadData()

        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        if (e.response.status !== HttpStatus.CONFLICT) {
          this.$notification.openMessageXhrError('Receipt Entry', e)
        }
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    async deleteEntity(saveEntity) {
      this.entity.deleted = true
      if (saveEntity) {
        this.save(true)
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && this.isSaveButton && !this.readOnlyView) {
        this.isUnsavedModalActive = true
      } else {
        if (this.returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.invoiceId)
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: ReceiptEntryRoutes.ReceiptEntryListView.name
          })
        }
      }
    },
    print() {
      const params = {
        reportname: 'rptTableItemsQuick',
        RecordID: this.entity.assetId,
        CompanyID: this.$userInfo.companyId
      }
      const url =
        `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx?` +
        Object.keys(params)
          .map((e) => `${e}=${params[e]}`)
          .join('&')
      window.open(url, '_blank')
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    async print1() {
      //Parse XML
      const partsXml = this.generatePartsXml()
      //Get CustomerId
      let customerId
      let assetType

      if (!this.entity.customerId) {
        customerId = this.entity.insurerId
        assetType = AssetTypes.Insurer
      } else {
        customerId = this.entity.customerId
        assetType = AssetTypes.Customer
      }

      //Set Parametters
      const params = Object.assign({
        REPORTTITLE: 'OFFICIAL RECEIPT',
        reportDocId: this.$lzstring.compressToBase64(partsXml),
        COMPANYNAME: this.$userInfo.companyName,
        CustomerId: customerId,
        CompanyID: this.$userInfo.companyId,
        TotalAmount: this.entity.totalAmount
      })

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const assetEmail = await QuickInvoiceService.getAssetEmail(customerId, assetType)
      const emailer = new Emailer()
      if (assetEmail) {
        emailer.toEmails.push(assetEmail)
      }
      emailer.assetId = customerId
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.subject = 'Receipt Payment to ' + this.entity.companyName
      emailer.reportName = 'ReceiptPayment_' + this.entity.companyName
      this.addEmailer(emailer)
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_OfficialReceiptList' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    generatePartsXml() {
      let xmlDoc = document.implementation.createDocument('', '', null)
      let mode
      let reference
      let bsb = ''
      let officialReceiptListElement = xmlDoc.createElement('AT_OfficialReceiptList')

      for (let i = 0; i < this.entity.invoiceItems.length; i++) {
        if (this.entity.invoiceItems[i].isChecked) {
          let officialReceiptElement = xmlDoc.createElement('OfficialReceiptList')
          let invNoElement = xmlDoc.createElement('InvNo')
          let invDateAmountElement = xmlDoc.createElement('InvDate')
          let invAmountElement = xmlDoc.createElement('InvAmount')
          let balanceElement = xmlDoc.createElement('Balance')
          let paymentElement = xmlDoc.createElement('Payment')
          let referenceElement = xmlDoc.createElement('Reference')
          let modeElement = xmlDoc.createElement('mode')
          let bsbElement = xmlDoc.createElement('bsb')

          if (
            (this.readOnlyView && roundAwayFromZero(this.entity.invoiceItems[i].balance, 2) === 0) ||
            (this.entity.isNew && roundAwayFromZero(this.entity.invoiceItems[i].balance, 2) === roundAwayFromZero(this.entity.invoiceItems[i].receivedAmount, 2))
          ) {
            mode = 'Full'
          } else {
            mode = 'Partial'
          }
          if (!this.entity.invoiceItems[i].chequeNo) {
            reference = ''
          } else {
            reference = this.entity.invoiceItems[i].chequeNo
          }
          let currentBalance = this.readOnlyView
            ? this.entity.invoiceItems[i].balance
            : this.entity.invoiceItems[i].balance - this.entity.invoiceItems[i].receivedAmount
          invNoElement.innerHTML = this.entity.invoiceItems[i].invoiceRef.escapeXml()
          invDateAmountElement.innerHTML = this.formatDateLocale(this.entity.invoiceItems[i].invoiceDate, this.$userInfo.locale).toString().escapeXml()
          invAmountElement.innerHTML = this.entity.invoiceItems[i].invoiceIncGst.toString().escapeXml()
          balanceElement.innerHTML = currentBalance.toString().escapeXml()
          paymentElement.innerHTML = this.entity.invoiceItems[i].receivedAmount.toString().escapeXml()
          referenceElement.innerHTML = reference.escapeXml()
          modeElement.innerHTML = mode.escapeXml()
          bsbElement.innerHTML = bsb.escapeXml()

          officialReceiptElement.appendChild(invNoElement)
          officialReceiptElement.appendChild(invDateAmountElement)
          officialReceiptElement.appendChild(invAmountElement)
          officialReceiptElement.appendChild(balanceElement)
          officialReceiptElement.appendChild(paymentElement)
          officialReceiptElement.appendChild(referenceElement)
          officialReceiptElement.appendChild(modeElement)
          officialReceiptElement.appendChild(bsbElement)
          officialReceiptListElement.appendChild(officialReceiptElement)
        }
      }
      xmlDoc.appendChild(officialReceiptListElement)
      return new XMLSerializer().serializeToString(xmlDoc)
    },
    formatDateLocale(value, locale, format = '2-digit') {
      const date = new Date(Date.parse(`${value}`))
      const options = { year: 'numeric', month: format, day: '2-digit' }
      if (Intl) {
        return Intl.DateTimeFormat(locale, options).format(date)
      } else {
        return date.toLocaleDateString(locale, options)
      }
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      // this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.toRoute = null
    },
    async reloadData() {
      // this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|receiptpayment|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|receiptentry|type`, this.type)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|receiptentry|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|receiptentry|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: ReceiptPaymentRoutes.ReceiptPaymentDetail.name,
        params: { AssetId: id },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && this.isSaveButton && !this.readOnlyView) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.assetId)
      this.clearSessionStorage()
      this.clearRouteMeta()
      this.setReturnRoute({})
      next()
    }
  }
}
</script>